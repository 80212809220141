<template>
    <div>
        <WeekNavigation
            :month="currentMonth"
            :week="currentWeek"
            @prev="prevWeek()"
            @next="nextWeek()"
        />

        <slot
            :lunchMenu="lunchMenu"
            :days="days"
            :currentWeek="currentWeek"
            :currentMonth="currentMonth"
            :currentYear="currentYear"
            :loaded="loaded"
        />
    </div>
</template>

<script>
import WeekNavigation from '@/components/ui/WeekNavigation';
import LunchMenuService from '@/services/LunchMenuService';
import { weekdays } from '@/utils/utils';
import getISOWeek from 'date-fns/getISOWeek';
import getYear from 'date-fns/getYear';
import addWeeks from 'date-fns/addWeeks';
import subWeeks from 'date-fns/subWeeks';

export default {
    components: {
        WeekNavigation
    },

    data: () => ({
        loaded: false,
        date: null,
        currentWeek: null,
        currentMonth: null,
        currentYear: null,
        lunchMenu: null,
        days: weekdays
    }),

    async created () {
        this.date = new Date();

        this.initWeek();
    },

    methods: {
        initWeek () {
            this.$emit('change-week');

            this.setWeekAndYear();
            this.getLunchMenu();
        },

        setWeekAndYear () {
            this.currentWeek = getISOWeek(this.date);
            this.currentMonth = this.date.toLocaleString('sv-SE', { month: 'long' });
            this.currentYear = getYear(this.date);
        },

        prevWeek () {
            this.date = subWeeks(this.date, 1);
            this.initWeek();
        },

        nextWeek () {
            this.date = addWeeks(this.date, 1);
            this.initWeek();
        },

        async getLunchMenu () {
            this.loaded = false;
            this.lunchMenu = null;

            const res = await LunchMenuService.get(this.currentWeek, this.currentYear);

            if (!res.data || !res.data.lunchMenu) {
                this.emitChangedEvent();
                this.loaded = true;
                return;
            }

            this.lunchMenu = res.data.lunchMenu;

            this.emitChangedEvent();

            this.loaded = true;
        },

        emitChangedEvent () {
            this.$emit('changed', {
                lunchMenu: this.lunchMenu,
                week: this.currentWeek,
                year: this.currentYear
            });
        }
    }
};
</script>
