<template>
    <div>
        <div class="mb-10">
            <AdminTop
                heading="Lunchmenyer"
            />
        </div>

        <LunchMenuProvider
            v-slot="{ days }"
            @change-week="lunchMenu = null"
            @changed="changeLunchMenu"
        >
            <BaseForm
                v-if="lunchMenu"
                :loading="loading"
                submit-label="Spara"
                @submit="handleSubmit"
            >
                <FormGroup
                    v-for="(label, day) in days"
                    :key="day"
                    :label="label"
                    :name="day"
                >
                    <TextareaField
                        v-model="lunchMenu[day]"
                        :name="day"
                        required
                    />
                </FormGroup>
            </BaseForm>
        </LunchMenuProvider>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import LunchMenuProvider from '@/components/lunchMenu/LunchMenuProvider';
import BaseForm from '@/components/form/BaseForm';
import FormGroup from '@/components/form/FormGroup';
import TextareaField from '@/components/form/TextareaField';
import LunchMenuService from '@/services/admin/LunchMenuService';

export default {
    components: {
        AdminTop,
        LunchMenuProvider,
        BaseForm,
        FormGroup,
        TextareaField
    },

    data: () => ({
        lunchMenu: null,
        loading: false
    }),

    methods: {
        changeLunchMenu ({ lunchMenu, week, year }) {
            if (!lunchMenu) {
                this.lunchMenu = {
                    week: week,
                    year: year,
                    monday: null,
                    tuesday: null,
                    wednesday: null,
                    thursday: null,
                    friday: null
                };

                return;
            }

            this.lunchMenu = lunchMenu;
        },

        async handleSubmit () {
            this.loading = true;

            try {
                await LunchMenuService.update(this.lunchMenu);
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }

            this.loading = false;
        }
    }
};
</script>
