import * as API from '../API.js';

export default {
    get (week, year) {
        return API.apiClient.get('/admin/lunch-menus', {
            params: { week, year }
        });
    },

    update (payload) {
        return API.apiClient.post('/admin/lunch-menus', payload);
    }
};
